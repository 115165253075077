<template>
  <fade-in>
    <div
      id="FormCompleteHeader"
      class="header-container"
    >
      <div 
        v-for="(item, index) in lang[activeLang].header_form_complete"
        :key="item.key"
        :item="item"
        :index="index"
        :active-lang="activeLang"
      >
        <h2 v-if="item.h2">
          {{ item.h2 }}
        </h2>
        <p v-if="item.text">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="item.text" />
          <!--eslint-enable-->
        </p>
        <ul v-if="item.list">
          <!-- eslint-disable vue/no-v-html -->
          <li 
            v-for="li in item.list" 
            :key="li" 
            v-html="li" 
          />
          <!--eslint-enable-->
        </ul>
        <FormButton
          v-if="item.button"
          :input="{
            title: item.button.title,
            url: item.button.url
          }"
          :active-lang="activeLang"
        />
      </div>
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'
import FormButton from './FormButton.vue'

export default {
  name: 'FormCompleteHeader',
  components: {
    FormButton,
    FadeIn
  },
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  },
  mounted() {
    let elmnt = document.getElementById('FormCompleteHeader')
    elmnt.scrollIntoView()
    window.scrollTo(0,0)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.spacer {
  width: 2%;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h2 {
  text-align: left;
  font-size: 42px;
  margin: 25px 0 0;
}

p {
  text-align: left;
}

em {
  color: rgb(214, 88, 88);
}
</style>
