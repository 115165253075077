<template>
  <div>
    <p>
      {{
        lang[activeLang][
          'The ACLU Foundation of Northern California (ACLU-NC) does not handle matters that arise outside Northern California. Although there are exceptions, it does not generally assist in these types of cases:'
        ]
      }}
    </p>
    <ul>
      <li>
        <strong>{{
          lang[activeLang]['Criminal defense or post-conviction appeal']
        }}</strong>
      </li>
      <li>
        <strong>{{ lang[activeLang]['Family law/child custody'] }}</strong> -
        {{
          lang[activeLang][
            'The ACLU-NC generally does not provide assistance in family law cases involving disputes about divorces, child custody, parenting time, or visitation.'
          ]
        }}
      </li>
      <li>
        <strong>{{
          lang[activeLang]['Property disputes or building code issues']
        }}</strong>
      </li>
      <li>
        <strong>{{ lang[activeLang]['Tax problems'] }}</strong>
      </li>
      <li>
        <strong>{{ lang[activeLang]['Landlord-tenant issues'] }}</strong> -
        {{
          lang[activeLang][
            'The ACLU-NC does not generally get involved in disputes between tenants and their private landlords, unless the issue involves discrimination prohibited by statute or ordinance.'
          ]
        }}
      </li>
      <li>
        <strong>{{
          lang[activeLang]['Complaints about Lawyers or Judges']
        }}</strong>
      </li>
      <li>
        <strong>{{
          lang[activeLang][
            "Denial of workers' compensation or unemployment benefits"
          ]
        }}</strong>
        -
        {{
          lang[activeLang][
            'The ACLU-NC generally does not get involved in individual benefits cases, unless the issue involes discrimination prohibited by law or statute.'
          ]
        }}
      </li>
      <li>
        <strong>{{ lang[activeLang]['Employment'] }}</strong> -
        {{
          lang[activeLang][
            'The ACLU-NC usually cannot help when employees believe they were fired unjustly or were otherwise treated unfairly at work. This is especially true when the employer is a private company rather than a government agency. But when workers can show that they were fired or mistreated because of their race, gender, ethnic background, religion, disability or any other basis that violates anti-discrimination statutes, there is stronger legal protection.'
          ]
        }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InvalidCaseList',
  props: {
    activeLang: {
      type: String,
      default: 'English',
    },
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>


<style scoped>
p {
  font-size: 18px;
  margin: 0 1em;
  padding-bottom: 18px;
}

ul {
  margin: 0 1em;
  text-align: left;
  text-indent: 0;
}

li {
  font-size: 18px;
  line-height: 1.69;
}
</style>

