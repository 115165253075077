const axios = require('axios')

const formRequest = {
  apiData: {
    requests: [
      { cmd: 'get_relations', target: 'relation' },
      { cmd: 'get_ethnicities', target: 'ethnicity' },
      { cmd: 'get_prisons', target: 'prison' },
      { cmd: 'get_pronouns', target: 'pronoun' },
      { cmd: 'get_counties', target: 'county' },
      { cmd: 'get_states', target: 'state_code' },
      { cmd: 'get_salutations', target: 'salutation' }
    ]
  },
  getURL: 'https://alis.acluutah.org/api/get.php',
  postURL: 'https://alis.acluutah.org/api/post-ut.php',
  async getData(cmd, property) {
    //console.log('GETTING DATA', cmd, this.apiData[cmd])
    const request = await axios.get(this.getURL, {
      params: {
        cmd: cmd
      }
    })
    for (let i = 0; i < request.data.length; i++) {
      this.apiData[cmd].options.push(request.data[i][property])
    }
    return request.data
  },
  async requestFormData() {
    let built = false
    for (let i = 0; i < this.apiData.requests.length; i++) {
      let requestData = this.apiData.requests[i]
      if (!built) {
        this.apiData[requestData.cmd] = {}
        this.apiData[requestData.cmd].options = []
        // console.log('BUILT', requestData.cmd)
      } else {
        // console.log('REQUEST', i, requestData.cmd)
        await formRequest.getData(requestData.cmd, requestData.target)
      }

      if (!built && i == this.apiData.requests.length - 1) {
        i = -1
        built = true
      }
    }
    // console.log(this.apiData)
  },
  async submitForm(data) {
    var formData = new FormData()
    formData.append('data', data)
    const request = await axios.post(this.postURL, formData)
    return request.status
  }
}

export default formRequest
