<template>
  <fade-in>
    <div class="header-container">
      <div
        v-for="(item, index) in lang[activeLang].header"
        :key="item.key"
        :item="item"
        :index="index"
        :active-lang="activeLang"
      >
        <h2 v-if="item.h2">
          {{ item.h2 }}
        </h2>
        <p v-if="item.text">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="item.text" />
          <!--eslint-enable-->
        </p>
      </div>
      <p>
        <em>* </em> {{ lang[activeLang].required }}
      </p>
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'
import InvalidCaseList from './FAQ/InvalidCaseList.vue'
import Coverage from './FAQ/Coverage.vue'
import Process from './FAQ/Process.vue'

export default {
  name: 'FormHeader',
  components: {
    FadeIn,
    // eslint-disable-next-line vue/no-unused-components
    InvalidCaseList,
    // eslint-disable-next-line vue/no-unused-components
    Coverage,
    // eslint-disable-next-line vue/no-unused-components
    Process,
  },
  props: {
    activeLang: {
      type: String,
      default: 'English',
    },
  },
  data() {
    return {}
  },
  created() {

  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.spacer {
  width: 2%;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h2 {
  text-align: left;
  font-size: 42px;
  margin-bottom: 0px;
}

p {
  text-align: left;
  font-size: 20px;
}

em {
  color: rgb(214, 88, 88);
}
</style>
